<template>
  <v-layout class="zg-patient-form" column>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Imię"
          placeholder="Wpisz imię..."
          :required="true"
          :rules="textRules"
          :value.sync="value.first_name"
          :autosave="autosave"
          :syncId="syncId"
        ></LabelTextField>
      </v-flex>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Nazwisko"
          placeholder="Wpisz nazwisko..."
          :required="true"
          :rules="textRules"
          :value.sync="value.last_name"
          :autosave="autosave"
          :syncId="syncId"
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Numer telefonu"
          placeholder="Wpisz numer telefonu..."
          :value="value.phone"
          disabled
        ></LabelTextField>
      </v-flex>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          class="xs-7"
          label="Adres e-mail"
          placeholder="Wpisz adres e-mail..."
          :value="value.email"
          disabled
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Numer PESEL"
          placeholder="Wpisz numer pesel..."
          :rules="nonRequiredPESEL"
          :value.sync="value.pesel"
          :autosave="autosave"
          :syncId="syncId"
          :emptyError="isNonPeselIdSet"
          required
        ></LabelTextField>
      </v-flex>
      <v-flex xs12 sm6 class="mt-1">
        <v-layout>
          <LabelDateField
            label="Data urodzenia"
            :value.sync="value.birth_date"
            :autosave="autosave"
            :syncId="syncId"
          ></LabelDateField>
          <AgeLabel :patient="value"></AgeLabel>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <GenderSelect
          :value.sync="value.gender"
          :autosave="autosave"
          :syncId="syncId"
        ></GenderSelect>
      </v-flex>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Zawód/hobby"
          placeholder="Wpisz zawód lub hobby pacjenta..."
          :value.sync="value.work"
          :autosave="autosave"
          :syncId="syncId"
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-divider class="mb-6 mx-4"></v-divider>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <CountrySelect
          :value.sync="value.country"
          :autosave="autosave"
          :syncId="syncId"
          required
        ></CountrySelect>
      </v-flex>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Miasto"
          placeholder="Wpisz miasto..."
          :value.sync="value.city"
          :autosave="autosave"
          :syncId="syncId"
          emptyError
          required
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Ulica"
          placeholder="Wpisz ulicę..."
          :value.sync="value.street"
          :autosave="autosave"
          :syncId="syncId"
          emptyError
          required
        ></LabelTextField>
      </v-flex>

      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Numer domu"
          placeholder="Wpisz numer domu..."
          :value.sync="value.house_number"
          :autosave="autosave"
          :syncId="syncId"
          emptyError
          required
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Numer lokalu"
          placeholder="Wpisz numer lokalu..."
          :value.sync="value.permises_number"
          :autosave="autosave"
          :syncId="syncId"
        ></LabelTextField>
      </v-flex>

      <v-flex xs12 sm6 class="mt-1">
        <LabelTextField
          label="Kod pocztowy"
          placeholder="Wpisz kod pocztowy..."
          :value.sync="value.zipcode"
          :autosave="autosave"
          :syncId="syncId"
          emptyError
          required
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-divider class="my-6 mx-4"></v-divider>
    <v-layout column class="mt-1">
      <LabelTextArea
        label="Uwagi"
        placeholder="Wpisz uwagi..."
        :value.sync="value.comments_field"
        :autosave="autosave"
        :syncId="syncId"
      ></LabelTextArea>
    </v-layout>
  </v-layout>
</template>

<script>
import Validators from "@/mixins/Validators";
import PatientUtils from "@/utils/patient.utils";
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin";

export default {
  mixins: [Validators, ProxyModificationMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showHistoryFieldDialog: false,
      historyFieldType: "",
      historyDialogSubtitle: "",
    };
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
    LabelDateField: () => import("@/components/LabelDateField"),
    LabelTextArea: () => import("@/components/LabelTextArea"),
    PhoneNumberInput: () => import("@/components/inputs/PhoneNumberInput"),
    CountrySelect: () => import("@/components/selects/CountrySelect"),
    GenderSelect: () => import("@/components/selects/GenderSelect"),
    AgeLabel: () => import("@/components/text/AgeLabel"),
  },
  computed: {
    isNonPeselIdSet() {
      return !PatientUtils.isNonPeselIdSet(this.value);
    },
  },
};
</script>

<style lang="css" scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>